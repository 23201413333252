@import '~antd/dist/antd.css';

.site-layout-background {
    background: rgba(4, 57, 72, 1);
}

.site-layout-content {
    background: rgba(4, 48, 61, 1);
}

.site-layout-sider {
    background: #014E60;
}

.ant-layout-sider {
    flex: 0 0 250px !important;
    max-width: 250px !important;;
    min-width: 250px !important;;
    width: 250px !important;;
}

.ant-form-item {
    margin-bottom: 10px;
}

.trigger {
    padding: 0 0px;
    margin: 0 -20px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    color: #FFFFFF;
}

.trigger:hover {
    color: #fff;
}

.ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;;
    min-width: 80px !important;;
    width: 80px !important;;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #014E60;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgba(4, 48, 61, 1);
}

.pad-10 {
    padding: 10px;
}

.pad-20 {
    padding: 20px;
}


body::-webkit-scrollbar {
  width: 1em;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

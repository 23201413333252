/* Extra small devices (phones, 600px and down) */
/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
#games-section {
  height: 100%;
}
#games-section #bottom-text {
  width: 100%;
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
  font-size: 28px;
}
#games-section #title {
  font-size: 65px;
  color: #21BDC4;
  font-weight: bolder;
  text-align: center;
}
#games-section #game-list-wrapper {
  margin-top: 50px;
}
#games-section .game-title {
  letter-spacing: 3.6px;
  font-weight: bolder;
  margin-top: 16px;
  text-align: center;
  color: #FFFFFF;
  font-size: 17px;
}
#games-section img {
  border-radius: 20px;
  box-shadow: 10px 10px 5px #21BDC4;
  -moz-box-shadow: 10px 10px 5px #21BDC4;
  -webkit-box-shadow: 10px 10px 5px #21BDC4;
  -khtml-box-shadow: 10px 10px 5px #21BDC4;
}
#games-section img.blur {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -ms-filter: blur(3px);
}
#games-section .game-availability-status {
  margin-top: 15px;
  text-align: center;
}
#games-section .game-availability-status .coming-soon-text {
  letter-spacing: 3px;
  font-weight: bolder;
  color: rgba(255, 255, 255, 0.5);
}
#games-signup-button {
  font-weight: bolder;
  padding: 20px 40px;
  height: auto;
  background: #21bdc4;
  color: #ffffff;
  border-radius: 20px;
  border: 2px solid #fff;
  font-size: 19px;
  letter-spacing: 3px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #games-section {
    height: auto;
  }
  #games-section #bottom-text {
    font-size: 25px;
  }
  #games-section #title {
    font-size: 45px;
  }
  #games-section .game-title {
    font-size: 17px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
  #games-section {
    height: auto;
  }
  #games-section #bottom-text {
    font-size: 1.5em;
  }
  #games-section #title {
    font-size: 2.5em;
  }
  #games-section #game-list-wrapper {
    margin-top: 50px;
  }
  #games-section .game-title {
    letter-spacing: 8px;
    margin-top: 16px;
  }
}
@media only screen and (max-width: 600px) {
  #games-section {
    height: auto;
  }
  #games-section #bottom-text {
    font-size: 1.5em;
  }
  #games-section #title {
    font-size: 2.5em;
    color: #21BDC4;
  }
  #games-section #game-list-wrapper {
    margin-top: 50px;
  }
  #games-section .game-title {
    letter-spacing: 8px;
    font-weight: bolder;
    margin-top: 16px;
    text-align: center;
    color: #FFFFFF;
  }
  .game-availability-status button {
    padding: 10px 10px;
    border-radius: 20px;
  }
}

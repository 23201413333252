.h2Title {
    margin: 20px;
    color: #FFFFFF;
}

.boxListProduct {
    min-height: 100px;
    border: 1px solid #323232;
    margin: 20px;
    border-radius: 10px;
    padding: 15px;
    background-color: #20222c;
}

.boxListProduct2 {
    min-height: 100px;
    border: 1px solid #323232;
    margin: 7px 7px 60px;
    border-radius: 10px;
    padding: 10px 10px 15px;
    background-color: #20222c;
}

.insideBoxListProduct {
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #2a2a2a;
}

.boxNftLogo {
    height: 40px;
    margin: 12px;
}

.nftValue {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 135%;
}

.nftValueConvert {
    color: #999999;
    font-size: 85%;
}

.nftImage {
    width: 100%;
    margin-bottom: -25px;
    margin-top: -90px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.textWhite90 {
    color: #FFFFFF;
    font-size: 90%;
}

.textGrey80 {
    color: #999999;
    font-size: 80%;
}

.hrGrey {
    padding: 0px;
    margin: 10px;
    color: #323232;
}

.axieClassTag {
    font-size: 80%;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 2px 7px;
}


/* Extra small devices (phones, 600px and down) */
/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
.dashboard-section {
  height: 100%;
}
.landing-title1-dashboard {
  font-size: 96px;
  color: #FFF;
  font-weight: bolder;
}
.landing-title2-dashboard {
  font-size: 36px;
  color: #21BDC4;
  font-weight: bolder;
}
#list-features {
  margin-top: 50px;
  font-size: 21px;
  color: #FFFFFF;
  list-style: none;
  padding-left: 0;
}
#list-features .ant-image {
  width: 85px;
  clear: both;
  float: left;
  margin-right: 1em;
}
#list-features li {
  margin-bottom: 30px;
}
#dashboard-image .ant-image {
  margin: 0 auto;
  display: table;
}
@media only screen and (min-width: 992px) and (max-width: 1399px) {
  .landing-title1-dashboard {
    font-size: 56px;
  }
  .landing-title2-dashboard {
    font-size: 26px;
  }
  .landing-description-dashboard {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-section {
    margin-top: 25%;
  }
  .landing-title1-dashboard {
    font-size: 66px;
  }
  .landing-title2-dashboard {
    font-size: 36px;
  }
  .landing-description-dashboard {
    font-size: 21px;
  }
  #dashboard-image {
    display: none;
  }
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
  .dashboard-section {
    margin-top: 25%;
  }
  .landing-title1-dashboard {
    font-size: 36px;
  }
  .landing-title2-dashboard {
    font-size: 36px;
  }
  .landing-description-dashboard {
    font-size: 21px;
  }
  #dashboard-image {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .dashboard-section {
    margin-top: 25%;
  }
  .landing-title1-dashboard {
    font-size: 36px;
  }
  .landing-title2-dashboard {
    font-size: 36px;
  }
  .landing-description-dashboard {
    font-size: 17px;
  }
  #list-features {
    font-size: 17px;
  }
  #list-features .ant-image {
    width: 70px;
  }
  #dashboard-image {
    display: none;
  }
}

.divMenuDesktop {
    border-bottom: 1px solid #323232;
    background-color: #17171a;
}

.divMenuMobile {
    border-bottom: 1px solid #323232;
    background-color: #17171a;
    display: none;
}

@media (max-width: 767px) {
    .divMenuDesktop {
        display: none;
    }
    .divMenuMobile {
        display: block;
    }
}

.mainContent{
    background-color: #1c1e26;
}


.ant-drawer-header {
    background-color: #17171a;
}

.ant-drawer-title {
    color: #FFFFFF;
}

.ant-drawer-body {
    background-color: #17171a;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #17171a;
}


.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #17171a;
}

.ant-menu-title-content {
    color: #ffffff !important;
}

.floatRight {
    float: right;
}

.mobileMenu {
    margin-top: 2px;
}

.header-logo {
    height: 25px;
}

body::-webkit-scrollbar {
  width: 1em;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

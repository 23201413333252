/* Extra small devices (phones, 600px and down) */
/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
#contribution-section {
  height: 100%;
}
#contribution-title {
  font-size: 72px;
  font-weight: bolder;
  color: #21BDC4;
}
#contribution-description {
  margin-top: 40px;
  font-size: 30px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 3px;
  line-height: 1.5em;
}
#contribution-link {
  margin-top: 40px;
}
#contribution-link span {
  color: #21BDC4;
  font-size: 36px;
  font-weight: bolder;
}
#contribution-button {
  margin-top: 40px;
}
#contribution-signup-button {
  font-weight: bolder;
  padding: 20px 40px;
  height: auto;
  background: #21bdc4;
  color: #ffffff;
  border-radius: 20px;
  border: 2px solid #fff;
  font-size: 19px;
  letter-spacing: 3px;
}
@media only screen and (max-width: 600px) {
  #contribution-title {
    font-size: 38px;
  }
  #contribution-description {
    font-size: 18px;
  }
  #contribution-link {
    margin-top: 40px;
  }
  #contribution-link span {
    color: #21BDC4;
    font-size: 26px;
    font-weight: bolder;
  }
}

/* Extra small devices (phones, 600px and down) */
/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
#about-section {
  height: 100%;
}
#about-head {
  font-size: 72px;
  font-weight: bolder;
  color: #FFFFFF;
}
#about-head span {
  color: #21BDC4;
}
#about-sub-head {
  margin-top: 30px;
  color: #FFFFFF;
  font-size: 30px;
  letter-spacing: 2px;
}
#about-require-container {
  margin-top: 50px;
}
#about-require-container #require-description {
  color: #21BDC4;
  font-size: 30px;
  font-weight: bolder;
}
#about-require-container #require-skill-list {
  display: flex;
  align-items: stretch;
  /* Default */
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin-top: 30px;
}
#about-require-container #require-skill-list li {
  display: block;
  flex: 0 1 auto;
  list-style-type: none;
  padding: 0 10px;
  color: #FFFFFF;
  font-size: 20px;
  text-align: center;
}
#about-require-container #require-skill-list li div {
  display: block;
}
#about-require-container #require-skill-list li div .ant-image {
  margin: 0 auto 15px;
  width: 125px;
  display: block;
}
#about-require-container #require-skill-list li:first-child {
  padding-left: 0;
}
#about-image .ant-image {
  margin: 0 auto;
  display: table;
  width: 70%;
}
@media only screen and (min-width: 992px) and (max-width: 1399px) {
  #about-head {
    font-size: 46px;
  }
  #about-sub-head {
    font-size: 25px;
  }
  #about-require-container #require-skill-list {
    justify-content: space-between;
  }
  #about-require-container #require-skill-list li div .ant-image {
    width: 100px !important;
  }
  #about-require-container #require-description {
    font-size: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #about-image .ant-image {
    display: none;
  }
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
  #about-image .ant-image {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  #about-sub-head {
    font-size: 18px;
  }
  #about-head {
    font-size: 42px;
  }
  #about-require-container #require-description {
    font-size: 25px;
  }
  #about-require-container #require-skill-list li div {
    display: block;
  }
  #about-require-container #require-skill-list li div .ant-image {
    margin: 0 auto 16px;
    width: 70px;
    display: compact;
  }
  #about-require-container #require-skill-list li:first-child {
    padding-left: 0;
  }
  #about-image .ant-image {
    display: none;
  }
}

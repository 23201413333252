/* Extra small devices (phones, 600px and down) */
/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
#tokenomic-section {
  height: 100%;
}
#tokenomic-sub-head {
  color: #FFF;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 3.6px;
}
#tokenomic-title {
  font-size: 48px;
  font-weight: bolder;
  color: #FFFFFF;
}
#tokenomic-title span {
  color: #21BDC4;
}
#tokenomic-table {
  margin-top: 40px;
  font-size: 17px;
  width: 100%;
  font-weight: bolder;
  color: #FFFFFF;
  border: none;
  border-collapse: collapse;
}
#tokenomic-table thead {
  background-color: #21BDC4;
}
#tokenomic-table thead th {
  padding: 10px;
}
#tokenomic-table tbody tr {
  background-color: rgba(33, 189, 196, 0.3);
}
#tokenomic-table tbody td {
  text-align: center;
  padding: 10px;
  border: 0 solid transparent;
}
#tokenomic-description {
  margin-top: 30px;
  color: #FFFFFF;
  font-size: 17px;
  letter-spacing: 2px;
  line-height: 1.6;
}
.pie-chart {
  height: 80vh !important;
}
#join-our-discord-link {
  color: #21BDC4;
  letter-spacing: 4px;
}
@media only screen and (max-width: 600px) {
  #tokenomic-sub-head {
    font-size: 15px;
  }
  #tokenomic-title {
    font-size: 45px;
  }
}

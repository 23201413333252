@import '~antd/dist/antd.css';

.ant-table-content{
    font-size: 95%;
}

.ant-transfer-list-content {
  font-size: 80%;
}


.header-right-margin-left {
    float: right;
    margin-left: 20px;
    cursor: pointer;
}

.dealer-opt-desktop{
    width: 320px;
    margin-left: 40px;
}
.dealer-opt-mobile{
    width: 150px;
    margin-left: 30px;
}

.layout {
    min-height: 100vh;
}

.small-text-grey {
    font-size: 80%;
    color: #7d7d7d;
}

.content-main-form {
    background: #fff;
}

.content-main-dashboard {
}

.content-pageheader {
    margin-bottom: 2em;
    background: #fff;
}

.avatar-uploader > .ant-upload {
    position: relative;
    width: 100%;
    height: 260px;
    overflow: hidden;
}

.avatar-uploader > .ant-upload img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.spinCenter {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.pt-5{
    padding-top: 5px;
}

.mt-5{
    margin-top: 5px;
}

.mb-10{
    margin-bottom: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .content-main-form {
        margin: 6px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .content-main-form {
        margin: 6px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .content-main-form {
        margin: 6px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .content-main-form {
        max-width: 600px;
        margin: 0px auto;
    }
}

.site-layout-content {
  padding: 10px;
  background: #fff;
}

.card-product {
    box-shadow: 0px 2px 7px 2px rgb(88, 126, 137, 1);
    min-height: 390px;
    background-color: #25BAC7;
    border-radius: 20px;

    margin-top: 20px;
    margin-bottom: 12px;
    margin-left: 3%;
    margin-right: 3%;
    text-align: left;
    padding: 3px;
}

.axie-image {
    width: 100%;
    margin-bottom: -25px;
    margin-top: -120px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.axie-resume {
    color:#fff;
    text-align: center;
}

.logo-defend{
    padding-right: 5px;
}
.logo-attack{
    padding-left: 20px;
    padding-right: 5px;
}
.logo-energy{
    padding-left: 20px;
    padding-right: 5px;
}


.card-detail {
    min-height: 220px;
    background-color: #0A6B77;;
    border-radius: 20px;

    margin-top: 20px;
    margin-bottom: 12px;
    margin-left: 3%;
    margin-right: 3%;
    text-align: left;
    padding: 3px;
}
